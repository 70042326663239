import { Box, TextField, Autocomplete } from '@mui/material'
import { HubApi } from '@services'
import { Field, FieldProps, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

interface FieldType {
  label: string
  description: string
  tagId: string | null
}

interface TagOption {
  id: string | null
  name: string
}

const validateLabel = (value: string) => {
  let error
  if (!value) error = 'O nome do cargo é obrigatório'
  return error
}

export const LabelDescriptionAndTagFields = () => {
  const { values, setFieldValue } = useFormikContext<FieldType>()
  const [tags, setTags] = useState<TagOption[]>([])

  const fetchTags = async () => {
    const roleTags = await HubApi.getRoleTags()
    const transformedTags = roleTags.map(tag => ({
      id: tag.id,
      name: tag.name
    }))
    setTags([{ id: null, name: 'Nenhuma' }, ...transformedTags])
  }

  useEffect(() => {
    fetchTags()
  }, [])

  return (
    <Box
      className="role-label-and-description"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        '& .MuiFormControl-root': {
          width: '90%'
        }
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          '& .MuiFormControl-root': {
            maxWidth: 'calc(45% - 8px)',
            flex: 1,
            justifyContent: 'space-between'
          }
        }}>
        <Field name="label" validate={validateLabel} required fullWidth>
          {({ field, meta }: FieldProps<string, FieldType>) => (
            <TextField
              {...field}
              label="Nome do cargo*"
              size="small"
              variant="standard"
              placeholder='Insira o nome do cargo, Ex: "Diretor Financeiro"'
              error={!!(meta.touched && meta.error)}
              helperText={meta.touched && meta.error}
            />
          )}
        </Field>
        <Field name="description">
          {({ field, meta }: FieldProps<string, FieldType>) => (
            <TextField
              {...field}
              label="Descrição do cargo"
              size="small"
              placeholder='Insira a descrição do cargo, Ex: "Responsável aprovar propostas"'
              variant="standard"
              error={!!(meta.touched && meta.error)}
              helperText={meta.touched && meta.error}
            />
          )}
        </Field>
      </Box>
      <Autocomplete
        options={tags}
        getOptionLabel={option => option.name}
        value={tags.find(tag => tag.id === values.tagId) || null}
        onChange={(_, value) => setFieldValue('tagId', value?.id || null)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField
            {...params}
            label="Tag do cargo"
            variant="standard"
            placeholder="Selecione uma tag"
          />
        )}
      />
    </Box>
  )
}
