import * as turf from '@turf/turf'

import { areas } from './Hub/Areas/Areas'
import { Assessments } from './Hub/Assessments'
import { AssessmentsCriminal } from './Hub/AssessmentsCriminal'
import { AssessmentsEnvironmental } from './Hub/AssessmentsEnvironmental'
import { AssessmentsFiscal } from './Hub/AssessmentsFiscal'
import { AssessmentsLabor } from './Hub/AssessmentsLabor'
import { Authorization } from './Hub/Authorization'
import { TagDTO } from './Hub/Authorization/contract/response/roles.response'
import { ProfileAnalysisRequest } from './Hub/ProfileAnalysisRequests'
import { axiosHub } from './config/axiosHub.config'

export type THubProperty = {
  id: string
  name: string
  sketchBoundary?: turf.MultiPolygon
}

export type THubDetectedSeason = {
  crop_name: string
  crops_id?: string
  area_id: string
  area_name: string
  start_date: string
  end_date: string
  validation: string
}

export type THubFormFieldDefinition = {
  id?: string
  kind?: string
  identifier: string
  entity: string
  label: string
  isRequired: boolean
  isEditable: boolean
  fieldType: string | null
  description: string | null
  customFieldGroupId: string | null
  components: any
  config: any
  source?: string
}

export type THubDocumentFileTemplates = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt?: string | null
  documentFileTypeId: string
  storagePath: boolean
  previewStoragePath: boolean
  config: any
  fileType: any
}

export type THubFormFieldGroup = {
  id: string
  label: string
}

export type ConditionAction = {
  fieldIdentifier: string
  type: string
  whenEvaluator: boolean
}

export type ConditionExpression = {
  fieldIdentifier: string
  operation: string
  value: string | boolean
}

export type THubFormConditionsDefinition = {
  name: string
  actions: ConditionAction[]
  expressions: ConditionExpression[]
}

export type THubStandardFormField = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  fieldIdentifier: string
  positionOnForm: number
  readOnly: boolean
  fieldDefinition: THubFormFieldDefinition
}

export type THubOrganizationForm = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  types: [string]
  title: string
  entity: string
  formFields: THubStandardFormField[]
  conditions: THubFormConditionsDefinition[]
}

const getCustomFields = async (organizationId: string | null) => {
  const { data } = await axiosHub.get<THubFormFieldDefinition[]>(
    `/api/v1/crm/custom-fields?organizationId=${organizationId}`
  )
  return data
}

const getCustomFieldsGroups = async (organizationId: string | null, formId?: string | null) => {
  const queryParameters = `organizationId=${organizationId}${formId ? `&formId=${formId}` : ''}`
  const { data } = await axiosHub.get<THubFormFieldGroup[]>(
    `/api/v1/crm/custom-fields-groups?${queryParameters}`
  )
  return data
}

const getNativeFields = async (entity?: string | null) => {
  const { data } = await axiosHub.get<THubFormFieldDefinition[]>(
    `/api/v1/crm/native-fields${entity ? `?entity=${encodeURIComponent(entity)}` : ''}`
  )
  return data
}

const getForms = async (organizationId: string | null) => {
  const { data } = await axiosHub.get<THubOrganizationForm[]>(
    `/api/v1/crm/forms?organizationId=${organizationId}`
  )
  return data
}

const getFormById = async (formId: string | null) => {
  const { data } = await axiosHub.get<THubOrganizationForm>(`/api/v1/crm/forms/${formId}`)
  return data
}

const getDocumentFileTemplates = async (organizationId: string | null) => {
  const { data } = await axiosHub.get<THubDocumentFileTemplates[]>(
    `/api/v1/crm/document-file-templates?organizationId=${organizationId}`
  )
  return data
}

const getDetectedSeasons = async (propertyId: string) => {
  const { data } = await axiosHub.get<THubDetectedSeason[]>(
    `/api/v1/land/area-seasons/detected-seasons?propertyId=${propertyId}`
  )
  return data
}

const getProperty = async (propertyId: string) => {
  const { data } = await axiosHub.get<THubProperty>(`/api/v2/properties/${propertyId}`)
  return data
}

const getRoleTags = async (): Promise<TagDTO[]> => {
  const { data } = await axiosHub.get<TagDTO[]>('/api/v1/authorization/role-tags')
  return data
}

export const HubApi = {
  areas,
  getProperty,
  getDetectedSeasons,
  getCustomFields,
  getCustomFieldsGroups,
  getNativeFields,
  getForms,
  getFormById,
  getDocumentFileTemplates,
  getRoleTags,
  AssessmentsCriminal,
  AssessmentsEnvironmental,
  AssessmentsFiscal,
  AssessmentsLabor,
  Assessments,
  ProfileAnalysisRequest,
  Authorization
}
