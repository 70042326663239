import { SUBJECT_DOMAINS } from '@/services/Hub/Authorization/authorization.constants'
import { UpdateRoleBody } from '@/services/Hub/Authorization/contract/request/role.request'
import { RoleDTO } from '@/services/Hub/Authorization/contract/response/roles.response'
import { SubjectWithDetailedActionsResponse } from '@/services/Hub/Authorization/contract/response/subjects.response'
import { HubApi } from '@services'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  IUseAuthorizationFormHook,
  TAuthData,
  TAuthorizationFormTab,
  TUseAuthorizationFormHook
} from '../AuthorizationForm.interface'

export const defaultAuthorizationFormTabs: TAuthorizationFormTab[] = [
  {
    label: 'CRM',
    value: SUBJECT_DOMAINS.CRM,
    index: 0
  },
  {
    label: 'Inspector',
    value: SUBJECT_DOMAINS.INSPECTOR,
    index: 1
  },
  {
    label: 'Platform',
    value: SUBJECT_DOMAINS.PLATFORM,
    index: 2
  },
  {
    label: 'Agreements',
    value: SUBJECT_DOMAINS.AGMT,
    index: 3
  },
  { label: 'Financing', value: SUBJECT_DOMAINS.FINANCING, index: 4 }
]

export const useAuthorizationFormHook = (
  props: IUseAuthorizationFormHook
): TUseAuthorizationFormHook => {
  const { extraTabs, selectedRole, onSubmissionCall, organizationId } = props
  const [currentTab, setCurrentTab] = useState<TAuthorizationFormTab>(
    defaultAuthorizationFormTabs[0]
  )
  const [isNormalizingRole, setIsNormalizingRole] = useState(true)
  const [detailedSubjects, setDetailedSubjects] = useState<
    TAuthData<SubjectWithDetailedActionsResponse[]>
  >({
    data: undefined,
    isLoading: true,
    hasError: false
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const availableTabs = useMemo(() => {
    const availableTabs = [...defaultAuthorizationFormTabs, ...(extraTabs || [])].sort(
      (a, b) => a.index - b.index
    )
    setCurrentTab(availableTabs[0])
    return availableTabs
  }, [extraTabs])

  const handleTabChange = useCallback((newValue: TAuthorizationFormTab) => {
    setCurrentTab(newValue)
  }, [])

  const fetchDetailedSubjects = useCallback(async () => {
    setDetailedSubjects(old => ({ ...old, isLoading: true }))
    try {
      const response = await HubApi.Authorization.getSubjectsWithDetailedAction()
      const onlySubjectsWithAction = response
        .filter(subject => !!subject.subjectActions?.length)
        .sort((a, b) => a.index - b.index)
      setDetailedSubjects({
        data: onlySubjectsWithAction,
        isLoading: false,
        hasError: false
      })
    } catch {
      enqueueSnackbar('Erro ao carregar as permissoes disponíveis no sistema', {
        variant: 'error'
      })
      setDetailedSubjects({
        data: undefined,
        isLoading: false,
        hasError: true
      })
    }
  }, [enqueueSnackbar])

  useEffect(() => {
    fetchDetailedSubjects()
  }, [fetchDetailedSubjects])

  const normalizedRole = useMemo(() => {
    if (!selectedRole) {
      setIsNormalizingRole(false)
      return {
        label: '',
        description: undefined,
        rules: []
      }
    }
    setIsNormalizingRole(true)
    const normalizedRules = (selectedRole.rules || [])
      .map(rule => {
        if (rule.id) {
          return rule
        }
        return {
          ...rule,
          id: uuidv4()
        }
      })
      .sort((a, b) => (a.inverted && !b.inverted ? 1 : -1))
    setIsNormalizingRole(false)
    return {
      label: selectedRole.label,
      description: selectedRole.description,
      rules: normalizedRules,
      tagId: selectedRole.tagId
    }
  }, [selectedRole])

  const isLoading = detailedSubjects.isLoading || isNormalizingRole

  const submitForm = async (roleToSubmit: UpdateRoleBody) => {
    setIsSubmitting(true)
    onSubmissionCall?.onSubmissionStart?.()
    try {
      const responseRole: RoleDTO = selectedRole?.id
        ? await HubApi.Authorization.updateRole(selectedRole.id, roleToSubmit)
        : await HubApi.Authorization.postRole({ ...roleToSubmit, organizationId })

      onSubmissionCall?.onSuccessfulSubmission?.(responseRole)
      enqueueSnackbar(`Cargo ${selectedRole?.id ? 'alterado' : 'criado'} com sucesso`, {
        variant: 'success'
      })
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      onSubmissionCall?.onFailedSubmission?.(selectedRole)
      console.error(error)
      enqueueSnackbar(`Erro ao ${selectedRole?.id ? 'alterar' : 'criar'} cargo`, {
        variant: 'error'
      })
    }
  }

  return {
    availableTabs,
    currentTab,
    handleTabChange,
    detailedSubjects: detailedSubjects.data,
    isLoading,
    hasError: detailedSubjects.hasError,
    normalizedRole,
    isSubmitting,
    submitForm
  }
}
