import { ContactConsentDTO } from '@/services/Bender/Consents/contract/response/ContactConsents.response'
import { formatDateToLongText } from '@/utils/formatDate'
import { formatGovRegCode } from '@/utils/formatGovRegCode'
import { FlexBox, NavigateBackButton } from '@components'
import { OpenInNew } from '@mui/icons-material'
import { Alert, Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import { BENDER_CONSENT_STATUS } from '@services'

import { renderEmptyState } from '@shared'

import { RejectionDialogWithReason } from '../ProfileReview/components/RejectionDialogWithReason'
import { TypographyInline } from '../ProfileReview/components/TypographyInline'

interface IValidateConsentsView {
  profileName?: string
  profileGovRegCode?: string
  profileId?: string
  organizationName?: string
  consentType?: string
  handleOpenHubPage: () => void
  handleAcceptTask: () => void
  handleOpenPdf: () => void
  handleOpenAdditionalPdf: () => void
  rejectionDialog: {
    open: boolean
    handleOpen: () => void
    handleCancel: () => void
    handleConfirm: (reason: string) => void
  }
  isOpenHubDisabled: boolean
  isRequesting: boolean
  isFetchingTasks: boolean
  error: unknown
  pdfUrl?: string
  additionalPdfUrl?: string
  status?: BENDER_CONSENT_STATUS
  hasConsentError: boolean
  consent?: ContactConsentDTO
}

export const ValidateConsentsView = (props: IValidateConsentsView) => {
  const {
    profileName,
    profileGovRegCode,
    profileId,
    organizationName,
    handleOpenHubPage,
    handleAcceptTask,
    handleOpenPdf,
    handleOpenAdditionalPdf,
    rejectionDialog,
    isRequesting,
    isFetchingTasks,
    error,
    consentType,
    pdfUrl,
    additionalPdfUrl,
    status,
    isOpenHubDisabled,
    hasConsentError,
    consent
  } = props

  return (
    <>
      <Box sx={{ height: 'calc(100vh - 3rem)', m: '32px 20px', maxWidth: 'calc(100vw - 40px)' }}>
        {renderEmptyState(isRequesting, error, 'Finalizando tarefa...') || (
          <>
            <Card sx={{ p: '2rem', borderRadius: '12px', gap: '8px' }}>
              <CardContent sx={{ padding: '0px' }}>
                <FlexBox
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}>
                  <FlexBox sx={{ gap: '1rem' }}>
                    <NavigateBackButton />

                    <Typography variant="h4" flexGrow={1} minWidth="max-content">
                      Validar anuência?
                    </Typography>
                  </FlexBox>
                  <FlexBox sx={{ justifyContent: 'space-between' }}>
                    <Button onClick={handleOpenHubPage} disabled={isOpenHubDisabled}>
                      <OpenInNew />
                      Abrir HUB
                    </Button>
                    <FlexBox sx={{ gap: 1 }}>
                      <Button
                        onClick={handleOpenPdf}
                        disabled={pdfUrl === undefined ? true : false}>
                        <OpenInNew />
                        Abrir PDF
                      </Button>
                      <Button
                        onClick={handleOpenAdditionalPdf}
                        disabled={additionalPdfUrl === undefined ? true : false}>
                        <OpenInNew />
                        Abrir PDF Adicional
                      </Button>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
                <Box>
                  <TypographyInline variant="subtitle1">Nome:</TypographyInline>
                  <TypographyInline>&nbsp;{profileName}</TypographyInline>
                </Box>
                <Box>
                  <TypographyInline variant="subtitle1">CPF/CNPJ:</TypographyInline>
                  <TypographyInline>&nbsp;{formatGovRegCode(profileGovRegCode)}</TypographyInline>
                </Box>
                <Box>
                  <TypographyInline variant="subtitle1">Profile ID:</TypographyInline>
                  <TypographyInline>&nbsp;{profileId}</TypographyInline>
                </Box>
                {organizationName ? (
                  <Box>
                    <TypographyInline variant="subtitle1">Organização:</TypographyInline>
                    <TypographyInline>&nbsp;{organizationName}</TypographyInline>
                  </Box>
                ) : null}
                {consentType ? (
                  <Box>
                    <TypographyInline variant="subtitle1">Type:</TypographyInline>
                    <TypographyInline>&nbsp;{consentType}</TypographyInline>
                  </Box>
                ) : null}
              </CardContent>
              <CardActions>
                <FlexBox vertical={true}>
                  {!isFetchingTasks &&
                    status &&
                    status !== BENDER_CONSENT_STATUS.REJECTED &&
                    status !== BENDER_CONSENT_STATUS.PENDING && (
                      <Alert severity="warning" sx={{ width: '100%' }}>
                        Essa tarefa já foi resolvida
                      </Alert>
                    )}
                  {!isFetchingTasks && hasConsentError && (
                    <Alert severity="error" sx={{ width: '100%' }}>
                      Houve um erro ao buscar as informarcoes, tente novamente.
                    </Alert>
                  )}
                  {!isFetchingTasks && status && status === BENDER_CONSENT_STATUS.REJECTED && (
                    <Alert severity="error" sx={{ width: '100%' }}>
                      Esta CPR foi revogada{' '}
                      {consent?.createdAt && `no dia ${formatDateToLongText(consent.createdAt)}`}, e
                      a justificativa foi enviada para o usuário do Inspector.
                    </Alert>
                  )}
                  {status === BENDER_CONSENT_STATUS.PENDING && (
                    <FlexBox>
                      <Button
                        onClick={rejectionDialog.handleOpen}
                        variant="discreet"
                        color="error"
                        sx={{ flexGrow: 1 }}>
                        Rejeitar
                      </Button>
                      <Button
                        onClick={handleAcceptTask}
                        variant="discreet"
                        color="success"
                        sx={{ flexGrow: 1 }}>
                        Validar
                      </Button>
                    </FlexBox>
                  )}
                </FlexBox>
              </CardActions>
            </Card>
          </>
        )}
      </Box>
      <RejectionDialogWithReason
        open={rejectionDialog.open}
        handleCancel={rejectionDialog.handleCancel}
        handleConfirm={rejectionDialog.handleConfirm}
        hasRejectionReasonInput={false}
      />
    </>
  )
}
